<template>
  <el-form :model="kufangchukuForm" :rules="rules" ref="form" label-width="100px" size="small">
    <el-form-item label="所属仓库" prop="cangku">
      <el-select v-model="kufangchukuForm.cangku" placeholder="请选择所属仓库">
        <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="材料名称" prop="cailiao">
      <el-select v-model="kufangchukuForm.cailiao" placeholder="请选择材料名称">
        <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="材料规格" prop="cailiaoguige">
      <el-select v-model="kufangchukuForm.cailiaoguige" placeholder="请选择材料规格">
        <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="出库数量" prop="amount">
      <el-input v-model.number="kufangchukuForm.amount"></el-input>
    </el-form-item>
    <el-form-item label="出库时间" prop="date">
      <el-date-picker
        type="date"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        v-model="kufangchukuForm.date"
        style="width: 100%;"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="出库备注" prop="remark">
      <el-input type="textarea" v-model="kufangchukuForm.remark"></el-input>
    </el-form-item>

    <el-form-item label="上传图片：" prop="photos">
      <el-upload
        ref="upload"
        action="http://ln-jz.cn:8000/uploadImg/"
        multiple
        accept="image/png, image/jpeg,image/gif"
        list-type="picture-card"
        :on-remove="handleRemove"
        :file-list="fileList"
        :before-upload="beforeUploadPicture"
        :on-success="uploadSuccess"
        :auto-upload="false"
      >
        <div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且单个文件不超过10M</div>
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      </el-upload>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    kufangchukuForm: Object,
    fileList: Array
  },
  data() {
    return {
      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      file_count: 0,

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择调配时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getCangkus() {
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.cangkus_options.push(cangku)
        })
        console.log('cangkus_options', this.cangkus_options)
      })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },

    // 上传图片前调用方法
    beforeUploadPicture(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message.error('上传图片不能大于10M')
        return false
      }
    },
    // 上传图片成功
    uploadSuccess(res, file, fileList) {
      this.file_count += 1
      this.fileChange(fileList)
      console.log('uploadfilelist.len:', fileList.length)
      console.log('file_count:', this.file_count)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('RemovefileList', fileList)
      this.fileChange(fileList)
    },
    // 设置photo字段值
    fileChange(fileList) {
      let temp_str = ''
      this.file_count = fileList.length
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            //列表中的第一个,作为temp_str的第一个元素
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += '/api/' + fileList[i].response.data //本地
                temp_str += fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += fileList[i].url
            }
          } else {
            //不是列表中的第一个,插入到temp_str中
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += ',' + '/api/' + fileList[i].response.data //本地
                temp_str += ',' + fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += ',' + fileList[i].url
            }
          }
        }
      }
      this.kufangchukuForm.photos = temp_str
      console.log('photos:', this.kufangchukuForm.photos)
    },
    submitUpload() {
      this.$refs.upload.submit()
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.file_count < 1) {
            console.log('fileList_len:', this.fileList.length)
            this.$message({
              type: 'alert',
              message: '请至少上传1张图片!',
              duration: 2000
            })
            this.isValidationOk = false
          } else {
            console.log('validation ok!')
            this.isValidationOk = true
          }
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    },
    initForm() {
      this.getCangkus(), this.getCailiaos(), this.getCailiaoguiges()
    }
  },
  created() {
    this.initForm()
  }
}
</script>

<style lang="scss" scoped></style>
